import React from "react";

const FDNAPage = () => (
  <div className="fdna"> 
    <link href="https://fonts.googleapis.com/css?family=Source+Code+Pro" rel="stylesheet"></link>
    <div style={{textAlign: 'center'}}>
      <h1>Face2Gene Features</h1>

        <div className="section">
          <h2>Syndrome Info For Patient</h2>
          <p>A modal window that displays information about a genetic syndrome in the contex of a patient. 
            In the top left part of the modal there are two images. The images are aligned on top of each other 
            for easier comparison.
          </p>
          <p>
            The clinician can compare the image of their patient with one of three options: 
            
            <ol className="feature-bullets">
              <li>A computer-generated mask with facial features that are typical for the syndrome.</li>
              <li>Heat map showing the most similar areas between the original image and the generated mask.</li>
              <li>Images that were published publicly of children that were diagnosed with the same syndrome.</li>
            </ol>
            </p>
          <video controls="controls" width="100%" name="Library Search" src="/videos/syndrome-info.mov"></video>
        </div>

        <div className="section">
          <h2>Documentation Of Genetic Test Results</h2>
          <p>A set of deeply nested forms with multiple variations. The form's structure is represented using JSON. Form updates are bubbled up
            the component tree to the top component. 
          </p>
          <video controls="controls" width="100%" name="Genomic Data" src="/videos/genomic.mov"></video>
        </div>

        <div className="section">
          <h2>Library Advanced Search</h2>
          <p>A search query UI built using a set of React components with JSON represented sructure. The search query is saved to 
            the database to support future enhancements of this feature.</p>
          <video controls="controls" width="100%" name="Library Search" src="/videos/library-search.mov"></video>
        </div>
    </div>
  </div>
)

export default FDNAPage
